.accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .accordion-item {
    border-top: 1px solid #1E5288;
    margin-bottom: 1px;
  }

  .accordion-bottom {
    border-bottom: 1px solid #1E5288;
  }

  .accordion-header {
    color: #fff;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
  }

  .accordion-content {
    display: none;
    padding: 10px;
  }

  .accordion-header::after {
    content: '\2303'; /* Unicode character for a right-pointing triangle or arrow */
    font-size: 25px;
    margin-left: 5px;
    transform: rotate(180deg);
}

  .expanded::after {
    transform: rotate(0deg);
}

.active {
    border-bottom: 2px solid #1E5288;
}

.activeAccordian{
    display: block;
}

.arizona-red-bg {
    background-color: #ab0520;
}

.arizona-blue-bg {
    background-color: #0C2348;
}

.brand {
    color: #AB0520;
    font-weight: 700;
}

.pad13 {
    padding: 13%;
}


.arizona-blue {
    color: #0C2348;
}

.azurite {
    color: #1E5288;
}

.cool-gray-bg {
    background-color: #E2E9EB;
}

.arizona-light-blue {
    color: #81D3EB;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 3px;
    margin: 6px 0;
    transition: 0.4s;
    background-color: white;
}

.burger {
    display: none;
}

.change .bar1 {
    transform: translate(0, 9px) rotate(-45deg);
}
  
.change .bar2 {
    opacity: 0;
}
  
.change .bar3 {
    transform: translate(0, -9px) rotate(45deg);
}
  

.border-bloom {
    border-left: 3px solid #EF4056;
}

.border-bottom-bloom {
    border-bottom: 1px solid #EF4056;
}

.click{
    &:after {
        border-bottom: .2125em solid transparent;
    border-left: .2125em solid transparent;
    border-right: .2125em solid white;
    border-top: .2125em solid white;
    content: '';
    display: inline-block;
    margin-bottom: 10px;
    }
}

.closed {
    display: none;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

// .eventDetailsButton::after {
//     content: '\2303'; /* Unicode character for a right-pointing triangle or arrow */
//     font-size: 25px;
//     margin-left: 5px;
//     transform: rotate(180deg);
//     display: block;
//     height: 30px;

// }

.azurite-border-r {
    border-right: 2px solid #378DBD;
}

.info-border {
    border-top: 2px dashed #EF4056;
    border-bottom: 2px dashed #EF4056;
}

.info-footer {
    max-width: 996px;
}

.link-text {
   
   width: 121px;
   &:hover{
    border-bottom: 2px solid #1E5288;
}
}

.link-overlay {
    position: absolute;
    bottom: 12px;
    padding: 30px 24px;
    color: white;
    font-size: 24px;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000CC 100%) 0% 0% no-repeat padding-box;
    height: 25%;
    width: 97%;
}

.open {
    display: none;
}

.pad24LR {
    padding: 0 24px;
}

.pad24 {
    padding: 24px;
}

.text-logo {
    width: 250px;
    text-align: left;    

    a {
        display: block;
        padding: 12px 0;
    }

    img {
        max-width: 250px;
        max-height: 17px;
    }
}

#box {
    position: relative;
}

.triangle::before,
.triangle::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
}

.triangle::before {
    border-right-color: #ccc;
    border-top-color: #ccc;
}

.triangle::after {
    margin-right: 12px;
    margin-top: 4px;
    border-width: 3px;
    border-right-color: #81D3EB;
    border-top-color: #81D3EB;
}

.last::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
    margin-top: 4px;
    border-width: 3px;
    border-right-color: #81D3EB;
    border-top-color: #81D3EB;
}

.redBar {
    content: "";
    width: 40px;
    height: 1px;
    margin: 15px 0;
    border-bottom: 1px solid red;
    margin-right: 10px;
}

.rule {
    border-bottom: 1px solid #1E5288;
    max-width: 449px;
}

.schedule {
    width: 260px;
}



@media screen and (max-width: 1212px) {
    .p-24-LR {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media screen and (max-width: 986px) {
    .top-rank{
        font-size: 2.5rem;
    }
}




@media screen and (max-width: 964px) {
    .schedule {
        width: 186px;
    }
}


@media screen and (max-width: 931px) {
    .nav {
        display: none;
    }

    .burger {
        display: block;
    }

    .open {
        display: block;
    }
}

@media screen and (max-width: 931px) {
    .link-overlay {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .schedule {
        width: 100%;
        p {
            padding-bottom: 5px;
        }
    }
}

@media screen and (min-width: 768px) {
    .hero{
        padding-left: 24px;
        padding-right: 24px;
    }
    
}



@media screen and (max-width: 640px) {

    .xs-width-full {
        width: 100%;
    }

    .margin-auto {
        margin-left: auto;
        margin-right: auto;
    }
}


@media screen and (max-width: 395px) {
    .link-overlay {
        width: 95%;
    }
}

@media screen and (max-width: 313px) {
    .link-overlay {
        width: 95%;
        font-size: 18px;
    }
}
